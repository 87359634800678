import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

import Logo from "../../img/logo.png";
import './Header.css';

import { withTranslation } from 'react-i18next';

class Header extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        const { t, i18n } = this.props;

        const changeLanguage = lng => {
            i18n.changeLanguage(lng); 
            localStorage.setItem('PAGE_LANUAGE', lng);
            
        };
        return (
            <Navbar className="headerNav" expand="lg">
                <Navbar.Brand className="brandLogo" href="/">
                    <img className="logo" src={Logo} alt="logo" />
                </Navbar.Brand>
                <div className="lng-block">
                        <button className="btn lng-outline bg_hotelRooms" onClick={() => changeLanguage('ua')}>ua</button>
                        <button className="btn lng-outline bg_hotelRooms" onClick={() => changeLanguage('en')}>en</button>
                    </div>
                <Navbar.Toggle aria-controls="menu-navbar" />
                <Navbar.Collapse id="menu-navbar">
                    <Nav className="menu">
                        <NavDropdown href="hotel" title={t('menu.hotelRooms.1')} id="basic-nav-dropdown">
                            <NavDropdown.Item href="/hotel/standart">{t('menu.hotelRooms.2')}</NavDropdown.Item>
                            <NavDropdown.Item href="/hotel/semilux">{t('menu.hotelRooms.3')}</NavDropdown.Item>
                            <NavDropdown.Item href="/hotel/lux">{t('menu.hotelRooms.4')}</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title={t('menu.houseRental.1')} id="basic-nav-dropdown">
                            <NavDropdown.Item href="/house-rent/house-on-water">{t('menu.houseRental.2')}</NavDropdown.Item>
                            <NavDropdown.Item href="/house-rent/house-on-land">{t('menu.houseRental.3')}</NavDropdown.Item>
                        </NavDropdown>
                        {/* <Nav.Link href="/banya">{t('menu.banya')}<span id="blink7">NEW!</span></Nav.Link> */}
                        <NavDropdown title={t('menu.infrastructure.1')} id="basic-nav-dropdown"> 
                            <NavDropdown.Item href="/fitnes">{t('menu.infrastructure.2')}</NavDropdown.Item>
                            <NavDropdown.Item href="/spa">{t('menu.infrastructure.3')}</NavDropdown.Item>
                            <NavDropdown.Item href="/beaury365">{t('menu.infrastructure.4')}</NavDropdown.Item>
                            <NavDropdown.Item href="/restoran">{t('menu.infrastructure.5')}</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href="/about">{t('menu.aboutUs')}</Nav.Link>
                        <Nav.Link href="/contacts">{t('menu.contacts')}</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default withTranslation()(Header);
