import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';

import { withTranslation } from 'react-i18next';

import ImgUrl1 from "../../img/hotelRooms/standart/standart-1.jpg";
import ImgUrl2 from "../../img/hotelRooms/standart/standart-2.jpg";
import ImgUrl3 from "../../img/hotelRooms/standart/standart-3.jpg";
import ImgUrl4 from "../../img/hotelRooms/standart/standart-4.jpg";

import Delux1 from "../../img/hotelRooms/delux/delux-1.jpg";
import Delux2 from "../../img/hotelRooms/delux/delux-2.jpg";
import Delux3 from "../../img/hotelRooms/delux/delux-3.jpg";
import Delux4 from "../../img/hotelRooms/delux/delux-4.jpg";
import Delux5 from "../../img/hotelRooms/delux/delux-5.jpg";
import Delux6 from "../../img/hotelRooms/delux/delux-6.jpg";
import Delux7 from "../../img/hotelRooms/delux/delux-7.jpg";

import Lux1 from "../../img/hotelRooms/lux/lux-1.jpg";
import Lux2 from "../../img/hotelRooms/lux/lux-2.jpg";
import Lux3 from "../../img/hotelRooms/lux/lux-3.jpg";
import Lux4 from "../../img/hotelRooms/lux/lux-4.jpg";
import Lux5 from "../../img/hotelRooms/lux/lux-5.jpg";
import Lux6 from "../../img/hotelRooms/lux/lux-6.jpg";
import Lux7 from "../../img/hotelRooms/lux/lux-7.jpg";
import Lux8 from "../../img/hotelRooms/lux/lux-8.jpg";

import './HotelRoomsPage.css';

class HotelRoomsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            roomIndex: 1
        };
    }

    componentDidMount() {
        switch (this.props.match.params.room) {
            case "standart":
                this.setState({ roomIndex: 0 });
                break;
            case "semilux":
                this.setState({ roomIndex: 1 });
                break;
            case "lux":
                this.setState({ roomIndex: 2 });
                break;
            default:
                this.setState({ roomIndex: 0 });
        }

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.room !== this.props.match.params.room) {
            switch (nextProps.match.params.room) {
                case "standart":
                    this.setState({ roomIndex: 0 });
                    break;
                case "semilux":
                    this.setState({ roomIndex: 1 });
                    break;
                case "lux":
                    this.setState({ roomIndex: 2 });
                    break;
                default:
                    this.setState({ roomIndex: 0 });
            }
        }

    }

    render() {
        const { t } = this.props;
        let rooms = [
            {
                name: t('hotelRoomPage.buttonRooms.1'),
                text: t('hotelRoomPage.text.1'),
                imgs: [ImgUrl1, ImgUrl2, ImgUrl3, ImgUrl4],
                price: t('hotelRoomPage.weekdaysPrices.1'),
                priceWeekend: t('hotelRoomPage.weekendsPrices.1'),
                size: t('hotelRoomPage.roomSize.1')
            },
            {
                name: t('hotelRoomPage.buttonRooms.2'),
                text: t('hotelRoomPage.text.2'),
                imgs: [Delux1, Delux2, Delux3, Delux4, Delux5, Delux6, Delux7],
                price: t('hotelRoomPage.weekdaysPrices.2'),
                priceWeekend: t('hotelRoomPage.weekendsPrices.2'),
                size: t('hotelRoomPage.roomSize.2')
            },
            {
                name: t('hotelRoomPage.buttonRooms.3'),
                text: t('hotelRoomPage.text.3'),
                imgs: [Lux1, Lux2, Lux3, Lux4, Lux5, Lux6, Lux7, Lux8],
                price: t('hotelRoomPage.weekdaysPrices.3'),
                priceWeekend: t('hotelRoomPage.weekendsPrices.3'),
                size: t('hotelRoomPage.roomSize.3')
            }
        ];


        return (
            <div>
                <Header />
                <div className="block_hotelRooms_H">
                    <Row>
                        <Col md="12" className="title title_p">
                            <p>{t('hotelRoomPage.title')}</p>
                        </Col>
                        <hr align="center" width="40%" size="1" color="#fff" />
                    </Row>

                    <Row>
                        <Col md="12" className="m20 nav-tabs_hotelRooms">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className={this.state.roomIndex === 0 ? 'active' : null} index={this.state.roomIndex}>
                                    <a className="nav-link" id="profile-tab" data-toggle="tab" onClick={() => { this.setState({ roomIndex: 0 }) }} href="/hotel/standart" role="tab" aria-controls="profile" aria-selected="false">{t('hotelRoomPage.buttonRooms.1')}</a>
                                </li>
                                <li className={this.state.roomIndex === 1 ? 'active' : null} index={this.state.roomIndex}>
                                    <a className="nav-link " id="contact-tab" data-toggle="tab" onClick={() => { this.setState({ roomIndex: 1 }) }} href="/hotel/semilux" role="tab" aria-controls="contact" aria-selected="false">{t('hotelRoomPage.buttonRooms.2')}</a>
                                </li>
                                <li className={this.state.roomIndex === 2 ? 'active' : null} index={this.state.roomIndex}>
                                    <a className="nav-link" id="contact-tab" data-toggle="tab" onClick={() => { this.setState({ roomIndex: 2 }) }} href="/hotel/lux" role="tab" aria-controls="contact" aria-selected="false">{t('hotelRoomPage.buttonRooms.3')}</a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className="m20">
                        <Col md="6" className="tabInfo">
                            <Row className="text">
                                <Col md="6" className="priceRoom">
                                    {t('hotelRoomPage.priceWeekdays')}
										    <p> {rooms[this.state.roomIndex].price} </p>
                                </Col>
                                <Col md="6" className="priceRoom_holiday">
                                    {t('hotelRoomPage.priceWeekends')}
										    <p>{rooms[this.state.roomIndex].priceWeekend} </p>
                                </Col>
                                <Col md="12">
                                    <span className="gold_color">{rooms[this.state.roomIndex].name}.</span>
                                    {rooms[this.state.roomIndex].text}
                                </Col>

                                <Col md="6"><b>{t('hotelRoomPage.size')}</b> {rooms[this.state.roomIndex].size}</Col>
                                <Col md="6">
                                    <a href="https://www.booking.com/hotel/ua/zafferano-vishenki.ru.html" target='_blank' rel="noopener noreferrer">
                                        <div className="btn btn-outline bg_hotelRooms"> {t('hotelRoomPage.buttonBook')}</div>
                                    </a>
                                </Col>
                                <Col md="12"><span className="green_color">{t('hotelRoomPage.wifi')}</span></Col>
                                <Col md="12"><b>{t('hotelRoomPage.smoking.1')}</b> <span className="red_color">{t('hotelRoomPage.smoking.2')}</span></Col>
                                <Col md="12"><span className="green_color">{t('hotelRoomPage.parking')}</span></Col>
                                <Row>
                                    <Col md="6">
                                        <ul>
                                            <p><b>{t('hotelRoomPage.inTheBathroom.1')}</b></p>
                                            <li>{t('hotelRoomPage.inTheBathroom.2')}</li>
                                            <li>{t('hotelRoomPage.inTheBathroom.3')}</li>
                                            <li>{t('hotelRoomPage.inTheBathroom.4')}</li>
                                            <li>{t('hotelRoomPage.inTheBathroom.5')}</li>
                                            <li>{t('hotelRoomPage.inTheBathroom.6')}</li>
                                            <li>{t('hotelRoomPage.inTheBathroom.7')}</li>
                                            <li>{t('hotelRoomPage.inTheBathroom.8')}</li>
                                        </ul>
                                    </Col>
                                    <Col md="6">
                                        <ul>
                                            <p><b>{t('hotelRoomPage.views.1')}</b></p>
                                            <li>{t('hotelRoomPage.views.2')}</li>
                                            <li>{t('hotelRoomPage.views.3')}</li>
                                            <li>{t('hotelRoomPage.views.4')}</li>
                                        </ul>
                                    </Col>
                                    <Col md="6">
                                        <ul>
                                            <p><b>{t('hotelRoomPage.services.1')}</b></p>
                                            <li>{t('hotelRoomPage.services.2')}</li>
                                            <li>{t('hotelRoomPage.services.3')}</li>
                                            <li>{t('hotelRoomPage.services.4')}</li>
                                            <li>{t('hotelRoomPage.services.5')}</li>
                                            <li>{t('hotelRoomPage.services.6')}</li>
                                            <li>{t('hotelRoomPage.services.7')}</li>
                                        </ul>
                                    </Col>
                                    <Col md="6">
                                        <ul>
                                            <li>{t('hotelRoomPage.services.8')}</li>
                                            <li>{t('hotelRoomPage.services.9')}</li>
                                            <li>{t('hotelRoomPage.services.10')}</li>
                                            <li>{t('hotelRoomPage.services.11')}</li>
                                            <li>{t('hotelRoomPage.services.12')}</li>
                                            <li>{t('hotelRoomPage.services.13')}</li>
                                        </ul>
                                    </Col>
                                    <Col md="6">
                                        <ul>
                                            <li>{t('hotelRoomPage.services.14')}</li>
                                            <li>{t('hotelRoomPage.services.15')}</li>
                                            <li>{t('hotelRoomPage.services.16')}</li>
                                            <li>{t('hotelRoomPage.services.17')}</li>
                                            <li>{t('hotelRoomPage.services.18')}</li>
                                        </ul>
                                    </Col>
                                    <Col md="6">
                                        <ul >
                                            <li>{t('hotelRoomPage.services.19')}</li>
                                            <li>{t('hotelRoomPage.services.20')}</li>
                                            <li>{t('hotelRoomPage.services.21')}</li>
                                            <li>{t('hotelRoomPage.services.22')}</li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Row>
                        </Col>

                        <Col md="6">
                            <Col md="12">
                                <Carousel interval={2000}>
                                    {rooms[this.state.roomIndex].imgs.map((img, num) => (
                                        <Carousel.Item key={num}>
                                            <Row>
                                                <Col lg="12">
                                                    <img className="carouselImg" alt="Стандарт" src={img} />
                                                </Col>
                                            </Row>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </Col>
                        </Col>
                    </Row>
                </div>
                <Footer />
            </div>
        );
    }
}

export default withTranslation()(HotelRoomsPage);
