import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { withTranslation } from 'react-i18next';

import './HotelRooms.css';

import RoomStandart from "../../img/hotelRooms/room-standart.jpg";
import Semilux from "../../img/hotelRooms/room-semilux.jpg";
import Lux from "../../img/hotelRooms/room-lux.jpg";



class HotelRooms extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <div className="block_hotelRooms">

                <div>
                    <div className="title title_p">
                        <p>{t('hotelRooms.title')}</p>
                    </div>
                    <hr align="center" width="40%" size="1" color="#fff" />
                </div>

                <div className="row">
                    <div className="col-md-12 ">
                        <div className="text_in_block bg_hotelRooms">
                            <p className="">
                                <span className="light_gold_color">Riviera Zoloche Resort & SPA</span> {t('hotelRooms.text')}
							<b>{t('hotelRooms.breakfast')}</b>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 title">
                        <p>{t('hotelRooms.title2')}</p>
                    </div>
                </div>

                <Row>
                    <Col lg="4">
                        <div className="photobox_hotelRooms photobox_type9">
						    <div className="photobox__previewbox">
							    <img src={RoomStandart} className="photobox__preview" alt="Preview" />
							    <span className="photobox__Staticklabel bg_hotelRooms ">{t('hotelRooms.rooms.1')}</span>

							    <div className="photobox__label">
								    <p>{t('hotelRooms.price.1')}</p>
								    <a href="/hotel/standart">
									    <div className="btn btn-outline bg_hotelRooms">{t('hotelRooms.button')}</div>
								    </a>
							    </div>
						    </div>
					    </div>
                    </Col>
                    <Col lg="4">
                        <div className="photobox_hotelRooms photobox_type9">
						    <div className="photobox__previewbox">
							    <img src={Semilux} className="photobox__preview" alt="Preview" />
							    <span className="photobox__Staticklabel bg_hotelRooms ">{t('hotelRooms.rooms.2')}</span>

							    <div className="photobox__label">
								    <p>{t('hotelRooms.price.2')}</p>
								    <a href="/hotel/semilux">
									    <div className="btn btn-outline bg_hotelRooms">{t('hotelRooms.button')}</div>
								    </a>
							    </div>
						    </div>
					    </div>
                    </Col>
                    <Col lg="4">
                        <div className="photobox_hotelRooms photobox_type9">
						    <div className="photobox__previewbox">
							    <img src={Lux} className="photobox__preview" alt="Preview" />
							    <span className="photobox__Staticklabel bg_hotelRooms ">{t('hotelRooms.rooms.3')}</span>

							    <div className="photobox__label">
								    <p>{t('hotelRooms.price.3')}</p>
								    <a href="/hotel/lux">
									    <div className="btn btn-outline bg_hotelRooms">{t('hotelRooms.button')}</div>
								    </a>
							    </div>
						    </div>
					    </div>
                    </Col>
                </Row>                
            </div>
        );
    }
}

export default withTranslation()(HotelRooms);
