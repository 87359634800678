import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';

import { withTranslation } from 'react-i18next';

import ImgUrl1 from "../../img/about/photo-1.jpg";
import ImgUrl2 from "../../img/about/photo-2.jpg";
import ImgUrl3 from "../../img/about/photo-3.jpg";
import ImgUrl4 from "../../img/about/photo-4.jpg";
import ImgUrl5 from "../../img/about/photo-5.jpg";
import ImgUrl6 from "../../img/about/photo-6.jpg";
import ImgUrl7 from "../../img/about/photo-7.jpg";
import ImgUrl8 from "../../img/about/photo-8.jpg";
import ImgUrl9 from "../../img/about/photo-9.jpg";
import ImgUrl10 from "../../img/about/photo-10.jpg";
import ImgUrl11 from "../../img/about/photo-11.jpg";
import ImgUrl12 from "../../img/about/photo-12.jpg";
import ImgUrl13 from "../../img/about/photo-13.jpg";
import ImgUrl14 from "../../img/about/photo-14.jpg";
import ImgUrl15 from "../../img/about/photo-15.jpg";

import './About.css';

class About extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            imgs: [ImgUrl1, ImgUrl2, ImgUrl3, ImgUrl4, ImgUrl5, ImgUrl6, ImgUrl7, ImgUrl8, ImgUrl9, ImgUrl10, ImgUrl11, ImgUrl12, ImgUrl13, ImgUrl14, ImgUrl15]
        };
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <Header />
                <div className="block_about">

                    <Row>
                        <Col md="12" className="title title_p">
                            <p>{t('about.title')} </p>
                        </Col>
                        <hr align="center" width="40%" size="1" color="#fff" />
                    </Row>

                    <Row>
                        <Row className="m35 tabInfo">
                            <Col md="6">
                                <p className="titleAbout">Riviera Zoloche Resort & SPA</p>
                                <p className="titleAbout">{t('about.title2')}</p>
                                <p><span className="gold_color">Riviera Zoloche Resort</span> {t('about.text')} </p>
                            </Col>
                            <Col md="6">
                                <Col md="12">
                                    <Carousel interval={2000}>
                                        {this.state.imgs.map((img, num) => (
                                            <Carousel.Item key={num}>
                                                <Row>
                                                    <Col lg="12">
                                                        <img className="carouselImg" key={num} alt="Стандарт" src={img} />
                                                    </Col>
                                                </Row>
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                </Col>
                            </Col>
                        </Row>
                    </Row>


                    <Row className="m10-0">
                        <Col md={{ span: 2, offset: 1 }} className=" col-md-offset-1 bigInfo m-r10">
                            <div> {t('about.bigInfo.1')}</div>
                            {t('about.bigInfo.2')}
                            </Col>
                        <Col md="2" className="bigInfo m-r10">
                            <div>{t('about.bigInfo.3')}</div>
                            {t('about.bigInfo.4')}
                            </Col>
                        <Col md="2" className="bigInfo m-r10">
                            <div>{t('about.bigInfo.5')}</div>
                            {t('about.bigInfo.6')}
                            </Col>
                        <Col md="2" className="bigInfo m-r10">
                            <div>{t('about.bigInfo.7')}</div>
                            {t('about.bigInfo.8')}
                            </Col>
                        <Col md="2" className="bigInfo m-r10">
                            <div>{t('about.bigInfo.9')}</div>
                            {t('about.bigInfo.10')}
                            </Col>
                    </Row>

                </div>
                <Footer />
            </div>
        );
    }
}

export default withTranslation()(About);
