import React, { Suspense } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Main from "./Main";

import './style/css/style.css';

import { hydrate } from 'react-dom';
import { useSSR } from 'react-i18next';
import './i18n';


const BaseApp = () => {
    useSSR(window.initialI18nStore, window.initialLanguage);
    return (
      <Suspense fallback={<div>loading...</div>}>
        <Main />
      </Suspense>
    );
  }
  
  hydrate(
    <BaseApp />,
    document.getElementById('root'),
  );
  