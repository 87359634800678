import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import './Infracstraction.css';

import { withTranslation } from 'react-i18next';

import Poster from "../../img/headerBack.jpg";
import Video from "../../img/videoInf.mp4";
import ImgUrl1 from "../../img/Infracstraction/inf-1.jpg";
import ImgUrl2 from "../../img/Infracstraction/inf-2.jpg";
import ImgUrl3 from "../../img/Infracstraction/inf-3.jpg";
import ImgUrl4 from "../../img/Infracstraction/inf-4.jpg";
import ImgUrl5 from "../../img/Infracstraction/inf-5.jpg";
import ImgUrl6 from "../../img/Infracstraction/inf-6.jpg";
import ImgUrl7 from "../../img/Infracstraction/inf-7.jpg";
import ImgUrl8 from "../../img/Infracstraction/inf-8.jpg";
import ImgUrl9 from "../../img/Infracstraction/inf-9.jpg";
import ImgUrl10 from "../../img/Infracstraction/inf-10.jpg";
import ImgUrl11 from "../../img/Infracstraction/inf-11.jpg";
import ImgUrl12 from "../../img/Infracstraction/inf-12.jpg";
import ImgUrl13 from "../../img/Infracstraction/inf-13.jpg";
import ImgUrl14 from "../../img/Infracstraction/inf-14.jpg";
import ImgUrl15 from "../../img/Infracstraction/inf-15.jpg";
import ImgUrl16 from "../../img/Infracstraction/inf-16.jpg";
import ImgUrl17 from "../../img/Infracstraction/inf-17.jpg";
import ImgUrl18 from "../../img/Infracstraction/inf-18.jpg";
import ImgUrl19 from "../../img/Infracstraction/inf-19.jpg";
import ImgUrl20 from "../../img/Infracstraction/inf-20.jpg";
import ImgUrl21 from "../../img/Infracstraction/inf-21.jpg";
import ImgUrl22 from "../../img/Infracstraction/inf-22.jpg";
import ImgUrl23 from "../../img/Infracstraction/inf-23.jpg";
import ImgUrl24 from "../../img/Infracstraction/inf-24.jpg";
import ImgUrl25 from "../../img/Infracstraction/inf-25.jpg";
import ImgUrl26 from "../../img/Infracstraction/inf-26.jpg";
import ImgUrl27 from "../../img/Infracstraction/inf-27.jpg";
import ImgUrl28 from "../../img/Infracstraction/inf-28.jpg";
import ImgUrl29 from "../../img/Infracstraction/inf-29.jpg";
import ImgUrl30 from "../../img/Infracstraction/inf-30.jpg";
import ImgUrl31 from "../../img/Infracstraction/inf-31.jpg";
import ImgUrl32 from "../../img/Infracstraction/inf-32.jpg";
import ImgUrl33 from "../../img/Infracstraction/inf-33.jpg";
import ImgUrl34 from "../../img/Infracstraction/inf-34.jpg";
import ImgUrl35 from "../../img/Infracstraction/inf-35.jpg";
import ImgUrl36 from "../../img/Infracstraction/inf-36.jpg";
import ImgUrl37 from "../../img/Infracstraction/inf-37.jpg";
import ImgUrl38 from "../../img/Infracstraction/inf-38.jpg";
import ImgUrl39 from "../../img/Infracstraction/inf-39.jpg";
import ImgUrl40 from "../../img/Infracstraction/inf-40.jpg";
import ImgUrl41 from "../../img/Infracstraction/inf-41.jpg";
import ImgUrl42 from "../../img/Infracstraction/inf-42.jpg";
import ImgUrl43 from "../../img/Infracstraction/inf-43.jpg";
import ImgUrl44 from "../../img/Infracstraction/inf-44.jpg";
import ImgUrl45 from "../../img/Infracstraction/inf-45.jpg";
import ImgUrl46 from "../../img/Infracstraction/inf-46.jpg";


class Infracstraction extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            imgs: [{
                tittle: "tittle",
                text: "text",
                url: ImgUrl1,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl2,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl3,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl4,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl5,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl6,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl7,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl8,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl9,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl10,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl11,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl12,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl13,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl14,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl15,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl16,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl17,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl18,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl19,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl20,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl21,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl22,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl23,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl24,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl25,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl26,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl27,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl28,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl29,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl30,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl31,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl32,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl33,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl34,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl35,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl36,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl37,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl38,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl39,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl40,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl41,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl42,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl43,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl44,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl45,
            }, {
                tittle: "tittle",
                text: "text",
                url: ImgUrl46,
            },
            ]
        };
    }

    render() {
        const { t } = this.props;
        return (
            <div className="block_infracstraction" name="infracstraction">

                <div>
                    <div className="title title_p">
                        <p> {t('infrastructure.title')}</p>
                    </div>
                    <hr align="center" width="40%" size="1" color="#fff" />
                </div>


                <Row>
                    <Row className="m35">
                        <Col md="6" className="tabInfo">
                            <div className="text">
                                <Row md="12">
                                    {t('infrastructure.text')}
                                </Row>
                                <Row md="12">

                                    <ul className="col-md-6">
                                        <p><b>Riviera Zoloche Resort & SPA:</b></p>
                                        <li>{t('infrastructure.rs.1')}</li>
                                        <li>{t('infrastructure.rs.2')}</li>
                                        <li>{t('infrastructure.rs.3')}</li>
                                        <li>{t('infrastructure.rs.4')}</li>
                                        <li>{t('infrastructure.rs.5')}</li>
                                        <li>{t('infrastructure.rs.6')}</li>
                                        <li>{t('infrastructure.rs.7')}</li>
                                        <li>{t('infrastructure.rs.8')}</li>
                                        <li>{t('infrastructure.rs.9')}</li>
                                        <li>{t('infrastructure.rs.10')}</li>
                                        <p><b>{t('infrastructure.rs.11')}</b></p>
                                        <li>{t('infrastructure.rs.12')}</li>
                                        <li>{t('infrastructure.rs.13')}</li>
                                    </ul>
                                    <ul className="col-md-6">
                                        <p><b>Riviera Zoloche:</b></p>
                                        <li>{t('infrastructure.rz.1')}</li>
                                        <li>{t('infrastructure.rz.2')}</li>
                                        <li>{t('infrastructure.rz.3')}</li>
                                        <li>{t('infrastructure.rz.4')}</li>
                                        <li>{t('infrastructure.rz.5')}</li>
                                        <li>{t('infrastructure.rz.6')}</li>
                                        <li>{t('infrastructure.rz.7')}</li>
                                        <li>{t('infrastructure.rz.8')}</li>
                                        <li>{t('infrastructure.rz.9')}</li>
                                        <li>{t('infrastructure.rz.10')}</li>
                                        <li>{t('infrastructure.rz.11')}</li>
                                        <li>{t('infrastructure.rz.12')}</li>
                                        <li>{t('infrastructure.rz.13')}</li>
                                    </ul>
                                </Row>
                                <Row md="12">
                                    <ul className="col-md-6">
                                        <p><b>{t('infrastructure.park.1')}</b></p>
                                        <li>{t('infrastructure.park.2')}</li>
                                        <li>{t('infrastructure.park.3')}</li>
                                        <li>{t('infrastructure.park.4')}</li>
                                        <li>{t('infrastructure.park.5')}</li>
                                        <li>{t('infrastructure.park.6')}</li>
                                        <li>{t('infrastructure.park.7')}</li>
                                        <li>{t('infrastructure.park.8')}</li>
                                        <li>{t('infrastructure.park.9')}</li>
                                    </ul>
                                    <ul className="col-md-6">                                        
                                        <p><b>Zoloche Vezuvyi:</b></p>
                                        <li>{t('infrastructure.vezuviy.1')}</li>
                                        <li>{t('infrastructure.vezuviy.2')}</li>
                                        <li>{t('infrastructure.vezuviy.3')}</li>
                                        <li>{t('infrastructure.vezuviy.4')}</li>
                                        <li>{t('infrastructure.vezuviy.5')}</li>
                                        <li>{t('infrastructure.vezuviy.6')}</li>
                                    </ul>
                                </Row>
                                <Row md="12">
                                    <ul className="col-md-6">
                                        <p><b>Zoloche:</b></p>
                                        <li>{t('infrastructure.zoloche.1')}</li>
                                        <li>{t('infrastructure.zoloche.2')}</li>
                                        <li>{t('infrastructure.zoloche.3')}</li>
                                        <li>{t('infrastructure.zoloche.4')}</li>
                                        <li>{t('infrastructure.zoloche.5')}</li>
                                        <li>{t('infrastructure.zoloche.6')}</li>
                                        <li>{t('infrastructure.zoloche.7')}</li>
                                        <li>{t('infrastructure.zoloche.8')}</li>
                                        <li>{t('infrastructure.zoloche.9')}</li>
                                        <li>{t('infrastructure.zoloche.10')}</li>
                                        <li>{t('infrastructure.zoloche.11')}</li>
                                        <li>{t('infrastructure.zoloche.12')}</li>
                                        <li>{t('infrastructure.zoloche.13')}</li>
                                        <li>{t('infrastructure.zoloche.14')}</li>
                                    </ul>
                                    <ul className="col-md-6">
                                        <p><b>{t('infrastructure.cityCenter.1')}</b></p>
                                        <li>{t('infrastructure.cityCenter.2')}</li>
                                        <li>{t('infrastructure.cityCenter.3')}</li>
                                        <li>{t('infrastructure.cityCenter.4')}</li>
                                        <li>{t('infrastructure.cityCenter.5')}</li>
                                        <li>{t('infrastructure.cityCenter.6')}</li>
                                        <li>{t('infrastructure.cityCenter.7')}</li>
                                        <li>{t('infrastructure.cityCenter.8')}</li>
                                        <li>{t('infrastructure.cityCenter.9')}</li>
                                        <li>{t('infrastructure.cityCenter.10')}</li>
                                        <li>{t('infrastructure.cityCenter.11')}</li>
                                    </ul>
                                </Row>
                            </div>
                        </Col>
                        <Col md="6">
                            <Col md="12" className="inf-video-wrapper">
                                <video preload="auto" poster={Poster} autoPlay="autoPlay" loop="loop" muted="">
                                    <source src={Video} type="video/mp4" />
                                </video>
                            </Col>
                            <Col md="12">
                                <Carousel interval={2000}>

                                    {this.state.imgs.map((img, num) => (
                                        <Carousel.Item key={num}>
                                            <Row>
                                                <Col lg="12">
                                                    <img className="carouselImg" alt={img.tittle} src={img.url} />

                                                </Col>
                                            </Row>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </Col>
                        </Col>
                    </Row>
                </Row>
            </div>
        );
    }
}

export default withTranslation()(Infracstraction);
